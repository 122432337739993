import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const QuickbooksIcon = createIcon('QuickbooksIcon', [
  [
    'path',
    {
      d: 'M4 12C4 13.1013 4.45384 14.1575 5.26169 14.9363C6.06954 15.715 7.16522 16.1525 8.30769 16.1525H8.92308V14.6102H8.30769C7.58957 14.6102 6.90086 14.3352 6.39306 13.8457C5.88527 13.3562 5.6 12.6923 5.6 12C5.6 11.3077 5.88527 10.6438 6.39306 10.1543C6.90086 9.66483 7.58957 9.38983 8.30769 9.38983H9.78462V17.4576C9.78462 17.6602 9.826 17.8607 9.90641 18.0479C9.98682 18.235 10.1047 18.405 10.2532 18.5482C10.4018 18.6915 10.5782 18.8051 10.7723 18.8826C10.9664 18.9601 11.1745 19 11.3846 19V7.84746H8.30769C7.16522 7.84746 6.06954 8.28496 5.26169 9.06371C4.45384 9.84246 4 10.8987 4 12ZM15.6923 7.84746H15.0769V9.38983H15.6923C16.4104 9.38983 17.0991 9.66483 17.6069 10.1543C18.1147 10.6438 18.4 11.3077 18.4 12C18.4 12.6923 18.1147 13.3562 17.6069 13.8457C17.0991 14.3352 16.4104 14.6102 15.6923 14.6102H14.2154V6.54237C14.2154 6.13331 14.0468 5.741 13.7468 5.45175C13.4467 5.1625 13.0397 5 12.6154 5V16.1525H15.6923C16.258 16.1525 16.8182 16.0451 17.3408 15.8364C17.8634 15.6278 18.3383 15.3219 18.7383 14.9363C19.1383 14.5507 19.4556 14.0929 19.6721 13.5891C19.8886 13.0853 20 12.5453 20 12C20 11.4547 19.8886 10.9147 19.6721 10.4109C19.4556 9.90708 19.1383 9.44931 18.7383 9.06371C18.3383 8.67811 17.8634 8.37224 17.3408 8.16355C16.8182 7.95487 16.258 7.84746 15.6923 7.84746Z',
      key: '1ukxsn',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default QuickbooksIcon;

import type { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import type { NextPageWithLayout } from '~/components/layout';
import type { PublicOutputs } from '~/generated/trpc/public';
import { LoginOrSignup } from '~/modules/account/ui/LoginOrSignup';
import { HtmlTitle } from '~/modules/ui/components/html-title';
import {
  AuthViewContentHeader,
  AuthViewTitle,
  DefaultAuthView,
} from '~/modules/ui/layouts/auth-view';
import type { inferSSRProps } from '~/server-shared/ssr';
import { getSSRProps } from '~/server-shared/ssr';
import { getReferrer } from '~/utils/referrals';

type Props = inferSSRProps<typeof getServerSideProps>;
const Page: NextPageWithLayout<Props> = () => {
  const router = useRouter();
  return (
    <LoginOrSignup>
      <AuthViewContentHeader>
        <AuthViewTitle>Sign in to your account</AuthViewTitle>
      </AuthViewContentHeader>
    </LoginOrSignup>
  );
};

Page.getLayout = (page) => (
  <DefaultAuthView options={{ showFooterWordmark: false }}>
    <HtmlTitle>Sign in</HtmlTitle>
    {page}
  </DefaultAuthView>
);

// This function is reused across auth pages
export function getServerSideProps(ctx: GetServerSidePropsContext) {
  return getSSRProps(ctx, async (opts) => {
    const viewer = await opts.trpc.viewer.public.me.fetch();

    if (viewer) {
      // If the user is already logged in, redirect them to the org page
      return {
        redirect: {
          destination: opts.searchParams.get('callbackUrl') || '/org',
          permanent: false,
        },
      };
    }
    const referrerSlug = getReferrer({
      cookies: ctx.req.cookies,
      query: ctx.query,
    });

    let referrer: PublicOutputs['orgNameBySlug'] | null = null;
    if (referrerSlug) {
      referrer = await opts.trpc.public.orgNameBySlug
        .fetch({
          slug: referrerSlug,
        })
        .catch(() => {
          // Using a catch to make sure it doesn't throw an error if the slug isn't found
          opts.log.warn(
            `Could not find organization from referrer slug "${referrerSlug}"`,
          );
          return null;
        });
    }
    return {
      props: {
        referrer,
      },
    };
  });
}

export default Page;

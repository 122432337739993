import Head from 'next/head';

/**
 * Update document `<head>`'s `<title>`-property.
 */
export const HtmlTitle = (props: { children: string | string[] }) => {
  const text = Array.isArray(props.children)
    ? props.children.join('')
    : props.children;

  const withSuffix = `${text} • Tola`;
  return (
    <Head>
      <title>{withSuffix}</title>
    </Head>
  );
};
